<template>
  <div class="finance-data">
    <h5>库存管理</h5>

    <div class="mt-3">
      <div class="summary">
        <Card :title="'库存'">
          <Loading :loading="loading"></Loading>
          <div class="row text-center" v-if="!loading">
            <div class="col-sm-4">
              总计：{{ countIncome }}
            </div>
            <div class="col-sm-4">
              消耗：{{ countPay }}
            </div>
            <div class="col-sm-4">
              库存：{{ countRatio }}%
            </div>
          </div>
        </Card>
      </div>

      <div class="filter  mt-3">
        <form action="" class="form-inline" @submit="onSubmit">
          <div class="form-row align-items-center">
            <div class="col-auto">
              <input type="date" class="form-control form-control-sm" v-model="keywords.start">
            </div>
            <div class="col-auto">
              到
            </div>
            <div class="col-auto">
              <input type="date" class="form-control form-control-sm" v-model="keywords.end">
            </div>
            <div class="col-auto">
              <select name="" id="" class="form-control form-control-sm"  v-model="keywords.type">
                <option value="-1" disabled selected>请选择类型</option>
                <option value="1">兑换彩票</option>
                <option value="2">兑奖支付</option>
              </select>
            </div>
            <div class="col-auto">
              <select name="" id="" class="form-control form-control-sm"  v-model="keywords.channel">
                <option value="-1" disabled selected>请选择渠道</option>
                <option value="1">中国移动</option>
                <option value="2">中国联通</option>
                <option value="3">中国电信</option>
              </select>
            </div>
            <div class="col-auto">
              <input type="text" class="form-control form-control-sm" v-model="keywords.orderSn" placeholder="订单号">
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary btn-sm">查询</button>
            </div>
          </div>
        </form>
      </div>

      <div class="list mt-3">
        <Loading :loading="loading"></Loading>
        <BTable striped hover :items="list" head-variant="dark" :fields="fields" v-if="!loading"></BTable>
      </div>
    </div>
  </div>
</template>
<script>
import Finance from "@/model/Finance"
import Loading from "@/components/Loading"
import { BTable } from 'bootstrap-vue'
import Card from "@/components/Card"

export default {
  components:{
    Card, BTable, Loading
  },
  data() {
    return {
      fields:[
        {key:'created_at', label:'交易时间'},
        {key:'orderSn',label:'订单号码'},
        {key:'type',label:'交易场景'},
        {key:'gameTitle', label: '批次名称'},
        {key:'gameCode', label: '批次编码'},
        {key:'status', label:'状态'},
        {key:'amount', label:'金额'}
      ],
      list: [],
      keywords: {
        start: '',
        end: '',
        type: -1,
        channel: -1,
        orderSn: ''
      },
      loading: false
    }
  },
  computed:{
    countIncome() {
      let r = 0;
      for(let i in this.list) {
        if(this.list[i].amount > 0) {
          r += this.list[i].amount;
        }
      }
      return r;
    },
    countPay() {
      let r = 0;
      for(let i in this.list) {
        if(this.list[i].amount < 0) {
          r += this.list[i].amount;
        }
      }
      return -r;
    },
    countRatio() {
      return this.countPay / this.countIncome * 100;
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      Finance.stock(this.keywords).then(response => {
        if(response.data.code === 0) {
          this.list = response.data.data;
        }
      })
    },
    onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      setTimeout(() => {this.loading = false}, 500)
      this.init();
    }
  }
}
</script>